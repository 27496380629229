<template>
  <div class="">
    <a href="/events" style="text-decoration: none">
      <img
        style="max-height: 50vh"
        class="oasis img-fluid rounded hover"
        src="../../assets/oasis.png"
      />
    </a>
    <a
      href="https://tickets.oztix.com.au/outlet/event/902ed5de-0a1d-468b-af74-d8126871dae2"
      target="_blank"
      style="text-decoration: none"
    >
      >
      <div class="text-white moderustic-font pt-5 hover link">
        <h1 style="letter-spacing: 8px">OASIS</h1>
        <h3 style="font-size: smaller">GET TICKETS NOW</h3>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'OasisBlock',
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap');
.moderustic-font {
  font-family: 'Moderustic', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.oasis {
  box-shadow: #2d40bb 0px 7px 29px 0px;
}
</style>
