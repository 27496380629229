<template>
  <nav class="navbar fixed-top navbar-expand-md navbar-dark">
    <div class="container-fluid">
      <!-- Navbar Brand (optional) -->
      <img
        v-if="isMobile()"
        class="navbar-brandb icon-link"
        style="max-height: 48px"
        href="/"
        src="../assets/icon.png"
      />

      <!-- Navbar Toggler Button -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Collapsible Navbar Content -->
      <div
        class="collapse navbar-collapse justify-content-center"
        id="navbarNav"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              href="/archives"
              :class="{ active: $route.path === '/archives' }"
              >ARCHIVES</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="/bookings"
              :class="{ active: $route.path === '/bookings' }"
              >BOOKINGS</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="/"
              :class="{ active: $route.path === '/' }"
              >HOME</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="/events"
              :class="{ active: $route.path === '/events' }"
              >EVENTS</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="/about"
              :class="{ active: $route.path === '/about' }"
              >ABOUT</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.3)
  ); /* Smooth gradient for blending */
  backdrop-filter: blur(15px); /*  blur radius  */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for softer edges */
  padding: 10px;
}

.nav-link.active {
  font-weight: bold;
  color: #fff; /*  active link stands out on dark background */
}

.navbar-nav .nav-item {
  margin-left: 15px;
  margin-right: 15px;
}

.navbar-toggler {
  margin-left: auto; /* This aligns the toggler to the right */
}
</style>
