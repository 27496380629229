<template>
  <NavBar></NavBar>
  <div class="">
    <!-- first section [main] -->
    <section class="child-snap d-flex main-bg">
      <div class="col">
        <coolLogo style="max-height: 30vh"></coolLogo>
        <a class="hover" href="#section2" style="text-decoration: none">
          <i class="fa-solid fa-angles-down text-white p-lg-5"></i>
        </a>
      </div>
    </section>
    <!-- second section [event]-->
    <section id="section2" class="child-snap d-flex oasis-bg">
      <OasisBlock></OasisBlock>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import coolLogo from '@/components/home/coolLogo.vue';
import NavBar from '@/components/NavBar.vue';
import OasisBlock from '@/components/home/OasisBlock.vue';
export default {
  name: 'HomeView',
  components: {
    coolLogo,
    NavBar,
    OasisBlock,
  },
};
</script>

<style>
/* 
Background stuff
 */

/* Make sure there is no leak */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Set the background color */
.main-bg {
  position: relative;
  background-color: #02030f !important;
}

/* Add a gradient overlay to the first section */
/*
.main-bg::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 50px; 
  background: linear-gradient(to bottom, #02030f 0%, rgba(2, 3, 15, 0) 100%);
  z-index: 2; 
}
*/

/* Add a gradient overlay to the second section */
.oasis-bg {
  position: relative;
  z-index: 1; /* Lower z-index to ensure the blend is visible */
  background: #02030f;
}

html {
  background-color: #02030f;
}

/* 
snapping behavior
 */

/* Set the snapping behavior */
html,
body {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-stop: always;
  scroll-padding: 0; /* Remove padding */
}

/* Make the snap smooth */
div {
  scroll-behavior: smooth;
}

/* Center content vertically and horizontally */
.child-snap {
  height: 100vh;
  scroll-snap-align: start;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ensure no extra space due to scroll margin or padding */
.child-snap {
  scroll-margin: 0 !important;
  scroll-padding: 0 !important;
  /* Ensure full-width sections */

  width: 100vw; /* Full viewport width */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  position: relative; /* Ensure the section is positioned relative to its parent */
}

/* Set box-sizing to border-box for consistent sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent any default browser spacing */
section,
div {
  position: relative;
  margin: 0;
  padding: 0;
}

.hover {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}
.hover:hover {
  top: -10px;
}
</style>
