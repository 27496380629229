<template>
  <div class="container my-5">
    <!-- Top row for President and Vice President -->
    <div class="row g-4 justify-content-center mb-4">
      <div
        class="col-12 col-sm-6 col-md-4 d-flex justify-content-center"
        v-for="(card, index) in presidentAndVicePresident"
        :key="index"
      >
        <div class="card" style="width: 18rem">
          <img
            :src="card.image"
            class="card-img-top rounded clickable"
            alt="Card image"
            @click="openModal(card)"
          />
          <div class="card-body">
            <h5 class="card-title">{{ card.name }}</h5>
            <p class="card-text fw-bold m-0">{{ card.role }}</p>
            <p class="card-text">{{ card.description }}</p>
            <button class="btn btn-about" @click="openModal(card)">
              View Profile
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Other members sorted by DOJ -->
    <div class="row g-4 justify-content-center">
      <div
        class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
        v-for="(card, index) in otherMembers"
        :key="index"
      >
        <div class="card" style="width: 18rem">
          <img
            :src="card.image"
            class="card-img-top rounded clickable"
            alt="Card image"
            @click="openModal(card)"
          />
          <div class="card-body">
            <h5 class="card-title">{{ card.name }}</h5>
            <p class="card-text fw-bold m-0">{{ card.role }}</p>
            <p class="card-text">{{ card.description }}</p>
            <button class="btn btn-about" @click="openModal(card)">
              View Profile
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal"
      id="profileModal"
      tabindex="-1"
      aria-hidden="true"
      @click="handleBackdropClick"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ selectedCard?.name || 'Profile' }}</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4 text-center">
                <img
                  :src="selectedCard?.image || ''"
                  alt="Profile Image"
                  class="img-fluid rounded"
                />
              </div>
              <div class="col-md-8 text-start">
                <p><strong>Role:</strong> {{ selectedCard?.role }}</p>
                <p>
                  <strong>Description:</strong> {{ selectedCard?.description }}
                </p>
                <p>
                  <strong>Favorite Genres:</strong> {{ selectedCard?.genre }}
                </p>
                <p>
                  <strong>Experience:</strong> {{ selectedCard?.experience }}
                </p>
                <p><strong>Socials:</strong></p>
                <div class="social-buttons">
                  <a
                    v-for="(value, key) in selectedCard?.socials"
                    :key="key"
                    :href="generateSocialLink(key, value)"
                    target="_blank"
                    rel="noopener noreferrer"
                    :class="getSocialStyle(key)"
                    class="btn d-flex align-items-center gap-2"
                  >
                    <i :class="getSocialIcon(key)"></i>
                    {{ capitalize(key) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal Footer with Share button -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="shareProfile"
            >
              <i class="fa fa-clipboard" aria-hidden="true"></i>

              Copy link to profile
            </button>
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import cards from '@/data/djs.json';

export default {
  name: 'AboutBlock',
  data() {
    return {
      cards, // Array of card objects
      selectedCard: null,
      socialsData: {
        //defines the behaviour for each social paltform
        instagram: {
          href: 'https://www.instagram.com/',
          class: 'fa-brands fa-instagram',
          style: 'btn btn-primary instagram border-0',
        },
        twitter: {
          href: 'https://twitter.com/',
          class: 'fa-brands fa-twitter',
          style: 'btn btn-primary twitter border-0',
        },
        email: {
          href: 'mailto:',
          class: 'fa-solid fa-envelope',
          style: 'btn btn-primary email border-0',
        },
        github: {
          href: 'https://github.com/',
          class: 'fa-brands fa-github',
          style: 'btn bg-black border-0',
        },
        spotify: {
          href: '',
          class: 'fa-brands fa-spotify',
          style: 'btn btn-primary spotify border-0',
        },
        youtube: {
          href: 'https://www.youtube.com/channel/',
          class: 'fa-brands fa-youtube',
          style: 'btn btn-primary youtube border-0',
        },
        soundcloud: {
          href: 'https://soundcloud.com/',
          class: 'fa-brands fa-soundcloud',
          style: 'btn btn-primary soundcloud border-0',
        },
      },
    };
  },
  computed: {
    //sorts the members
    presidentAndVicePresident() {
      return this.cards.filter((card) =>
        ['President', 'Vice President'].includes(card.role)
      );
    },
    otherMembers() {
      return this.cards
        .filter((card) => !['President', 'Vice President'].includes(card.role))
        .sort((a, b) => new Date(a.doj) - new Date(b.doj));
    },
  },
  mounted() {
    // Handle initial route load
    this.handleRouteChange();
  },

  watch: {
    // React to changes in the route parameter
    '$route.params.djName': 'handleRouteChange',
  },
  methods: {
    //methods for the dynamic social buttons
    generateSocialLink(platform, username) {
      const platformData = this.socialsData[platform.toLowerCase()];
      if (!platformData) return '#';
      return platform === 'spotify'
        ? username
        : `${platformData.href}${username}`;
    },
    getSocialStyle(platform) {
      const platformData = this.socialsData[platform.toLowerCase()];
      return platformData ? platformData.style : 'btn btn-secondary';
    },
    getSocialIcon(platform) {
      const platformData = this.socialsData[platform.toLowerCase()];
      return platformData ? platformData.class : 'fa-solid fa-question';
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    //modal methods
    slugify(name) {
      return name
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]/g, '');
    },
    // Open the modal with the selected DJ
    handleRouteChange() {
      const slug = this.$route.params.djName;

      if (slug) {
        // Convert the slug back to the DJ name to find the card
        const card = this.cards.find(
          (card) => this.slugify(card.djName) === slug
        );

        if (card) {
          this.openModal(card);
        } else {
          // Handle invalid slug (e.g., redirect or show an error)
          this.$router.replace('/about');
        }
      }
    },
    openModal(card) {
      this.selectedCard = card;
      const modalElement = document.getElementById('profileModal');
      if (modalElement) {
        Modal.getOrCreateInstance(modalElement).show();
      }

      // Use the slugified name in the URL
      const slug = this.slugify(card.djName);
      this.$router.push({ path: `/about/${slug}` });
    },
    closeModal() {
      const modalElement = document.getElementById('profileModal');
      if (modalElement) {
        Modal.getInstance(modalElement)?.hide();
      }

      // Reset the URL back to the main `/about` page
      this.$router.push('/about');
    },
    // Handle click on the backdrop (background area)
    handleBackdropClick(event) {
      // Check if the backdrop (modal background) is clicked, not the modal content
      if (event.target === event.currentTarget) {
        this.closeModal(); // Close the modal and reset the URL
      }
    },
    // Copy the current URL (with the DJ's name) to the clipboard
    shareProfile() {
      const slug = this.slugify(this.selectedCard.djName);
      const url = `${window.location.origin}/about/${encodeURIComponent(slug)}`;
      navigator.clipboard.writeText(url);
    },
  },
};
</script>
<style scoped>
img.clickable {
  cursor: pointer;
}
.card {
  background: rgba(0, 0, 0, 1);
  color: #f0f0f0;
}
.modal-content {
  background-color: #1a1a1a;
  color: #f0f0f0;
  box-shadow: 0 5px 6px black;
}

.card-body {
  background: rgba(0, 0, 0, 1);
}

.btn-about {
  background-color: white;
  color: black;
  border: none;
  transition: all 0.3s ease;
}

.btn-about:hover {
  background-color: #00bcd4;
}

.card-title {
  color: #00bcd4; /* Cool cyan for titles */
}

.card-text {
  color: #f0f0f0;
}
.social-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.btn-social {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: none;
  background-color: #00bcd4;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-social:hover {
  background-color: #008ba3;
}

.btn-social i {
  margin-right: 5px;
  font-size: 16px;
}
.instagram {
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}
.twitter {
  background: #1d9bf0;
}
.soundcloud {
  background-color: #ff7700;
}
.spotify {
  background: #1ed760;
}
.youtube {
  background: #ff0000;
}
</style>
