import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import axios from 'axios';

const app = createApp(App);

// Add Axios to the global properties so it can be accessed anywhere in the app
app.config.globalProperties.$axios = axios;

app.use(router).mount('#app');
