import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutBlock from '@/components/about/AboutBlock.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/about/:djName', // This handles dynamic djName
    name: 'AboutProfile',
    component: () => import('../views/AboutView.vue'),
    AboutBlock,
    props: true, // Pass route params as props to the component
  },
  {
    path: '/archives',
    name: 'archives',
    component: () => import('../views/ArchivesView.vue'),
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/EventsView.vue'),
  },
  {
    path: '/bookings',
    name: 'bookings',
    component: () => import('../views/BookingsView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
